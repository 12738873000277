@import 'tailwindcss/base';

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #eaf0fe transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.react-flow__controls-button {
  @apply !border-b-0 flex-1 !w-8 !h-6 !bg-white;
  fill: #000;
}

.react-flow__controls-button:hover {
  @apply !bg-white;
  fill: #3174fa !important;
}

.react-flow__controls-button.react-flow__controls-zoomin {
  @apply !order-2 rounded-l-lg;
}

.react-flow__controls-button.react-flow__controls-zoomout {
  @apply !order-4 rounded-r-lg;
}

.dotted-bg {
  @apply bg-flow-view;
  background-image: radial-gradient(#c4c4d0 0.7px, transparent 0);
  background-size: 16px 16px;
}

#sentry-feedback {
  --left: 50%;
}
