.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.\!absolute {
  position: absolute !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.left-\[50\%\] {
  left: 50%;
}

.right-0 {
  right: 0px;
}

.right-3 {
  right: 0.75rem;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0px;
}

.top-4 {
  top: 1rem;
}

.top-\[10\%\] {
  top: 10%;
}

.z-10 {
  z-index: 10;
}

.z-\[2\] {
  z-index: 2;
}

.z-\[99999\] {
  z-index: 99999;
}

.\!m-0 {
  margin: 0px !important;
}

.\!mr-0 {
  margin-right: 0px !important;
}

.\!mr-1 {
  margin-right: 0.25rem !important;
}

.\!mt-0 {
  margin-top: 0px !important;
}

.\!mt-8 {
  margin-top: 2rem !important;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-auto {
  margin-top: auto;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.\!hidden {
  display: none !important;
}

.hidden {
  display: none;
}

.\!h-10 {
  height: 2.5rem !important;
}

.h-12 {
  height: 3rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-\[calc\(100vh_-_92px\)\] {
  height: calc(100vh - 92px);
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.\!min-h-\[2rem\] {
  min-height: 2rem !important;
}

.\!w-10 {
  width: 2.5rem !important;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.w-max {
  width: -moz-max-content;
  width: max-content;
}

.\!min-w-max {
  min-width: -moz-max-content !important;
  min-width: max-content !important;
}

.min-w-0 {
  min-width: 0px;
}

.min-w-72 {
  min-width: 18rem;
}

.\!max-w-\[35rem\] {
  max-width: 35rem !important;
}

.\!max-w-max {
  max-width: -moz-max-content !important;
  max-width: max-content !important;
}

.max-w-max {
  max-width: -moz-max-content;
  max-width: max-content;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.\!flex-1 {
  flex: 1 1 0% !important;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.\!cursor-pointer {
  cursor: pointer !important;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.\!truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-all {
  word-break: break-all;
}

.\!rounded {
  border-radius: 0.25rem !important;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.\!border {
  border-width: 1px !important;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.\!border-gray-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.border-border {
  --tw-border-opacity: 1;
  border-color: rgb(238 241 244 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(25 25 25 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: transparent;
}

.border-b-gray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.\!bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}

.\!bg-primarySelected {
  background-color: rgba(33, 150, 243, 0.08) !important;
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(25 25 25 / var(--tw-bg-opacity));
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.\!fill-none {
  fill: none !important;
}

.\!p-0 {
  padding: 0px !important;
}

.p-12 {
  padding: 3rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.\!px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.\!py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.\!py-2\.5 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.\!py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.\!pb-8 {
  padding-bottom: 2rem !important;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-0 {
  padding-left: 0px;
}

.pl-0\.5 {
  padding-left: 0.125rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-left {
  text-align: left;
}

.align-baseline {
  vertical-align: baseline;
}

.\!text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.\!text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.\!font-normal {
  font-weight: 400 !important;
}

.\!font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-none {
  line-height: 1;
}

.\!text-\[\#393939\] {
  --tw-text-opacity: 1 !important;
  color: rgb(57 57 57 / var(--tw-text-opacity)) !important;
}

.\!text-primary {
  --tw-text-opacity: 1 !important;
  color: rgb(25 25 25 / var(--tw-text-opacity)) !important;
}

.\!text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}

.text-\[\#00000099\] {
  color: #00000099;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-error {
  --tw-text-opacity: 1;
  color: rgb(227 69 69 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-infoDark {
  --tw-text-opacity: 1;
  color: rgb(15 61 97 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(25 25 25 / var(--tw-text-opacity));
}

.text-primaryLight {
  --tw-text-opacity: 1;
  color: rgb(127 127 127 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-secondaryText {
  color: rgba(0, 0, 0, 0.60);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-95 {
  opacity: 0.95;
}

.\!shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.MuiAlert-root {
  top: 1.5rem !important;
  display: flex;
  align-items: center;
}

.MuiAlert-action {
  margin-right: 0px !important;
  padding-top: 0px !important;
}

.MuiAlert-message {
  color: inherit;
}

.custom-dialog .MuiPaper-root {
  width: 100% !important;
  max-width: -moz-max-content !important;
  max-width: max-content !important;
}

.custom-dialog.medium .MuiPaper-root {
  max-width: 35rem !important;
}

.custom-input {
  width: 100% !important;
}

.custom-input .Mui-disabled {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(57 57 57 / var(--tw-text-opacity)) !important;
}

.custom-input .MuiOutlinedInput-input:not(.MuiInputBase-inputMultiline) {
  padding: 0.75rem !important;
}

.custom-input.search-input .MuiOutlinedInput-input {
  padding: 0.75rem !important;
}

.custom-input:focus-within fieldset {
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.custom-input .MuiOutlinedInput-root {
  min-height: 3.5rem !important;
  font-weight: 400 !important;
}

.custom-input .MuiAutocomplete-inputRoot {
  min-height: 2.5rem !important;
  font-weight: 400 !important;
}

.custom-input fieldset {
  border-width: 1px !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.custom-input.has-error fieldset {
  --tw-border-opacity: 1 !important;
  border-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}

.custom-input.no-border fieldset {
  border-width: 0px !important;
}

.custom-select {
  min-height: 60px;
}

.custom-select .MuiSelect-select.MuiSelect-outlined {
  padding-bottom: 0.5rem !important;
}

.MuiOutlinedInput-notchedOutline legend {
  height: auto;
}

.MuiOutlinedInput-notchedOutline legend > span {
  opacity: 1;
}

.custom-select .MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.custom-select .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.custom-select
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.custom-tabs {
  min-height: 2rem !important;
  width: -moz-max-content;
  width: max-content;
  border-radius: 0.25rem !important;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(25 25 25 / var(--tw-border-opacity));
}

.custom-tabs .MuiTabs-indicator {
  display: none;
}

.custom-tabs .MuiTab-root {
  min-height: -moz-max-content;
  min-height: max-content;
  min-width: 0px;
  max-width: -moz-max-content;
  max-width: max-content;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  --tw-text-opacity: 1;
  color: rgb(25 25 25 / var(--tw-text-opacity));
}

.custom-tabs .MuiTab-root.Mui-selected {
  --tw-bg-opacity: 1;
  background-color: rgb(25 25 25 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

/* Chrome, Safari and Opera */

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.show-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

img {
  position: relative;
}

img[alt]:after {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  --tw-content: '';
  content: var(--tw-content);
}

.last\:border-b-2:last-child {
  border-bottom-width: 2px;
}

.first-of-type\:\!pl-2:first-of-type {
  padding-left: 0.5rem !important;
}

.last-of-type\:\!pr-0:last-of-type {
  padding-right: 0px !important;
}

.hover\:\!cursor-pointer:hover {
  cursor: pointer !important;
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
